import * as React from "react";
import Layout from "../components/Layout";
import "./tc.sass";

const PricePage = () => {
  return (
    <Layout>
      <div id="tc-container">
        <h2>Terms</h2>
        <p>
          Terms and conditions of use Effective as of 10/10/2020 <br /> <br />
          The purpose of these general terms and conditions of use (hereinafter
          referred to as "GTC") is to provide a legal framework for the
          provision of the weely.ch website and services and to define the
          conditions of access and use of the services by the "User". The
          present GTU are accessible on the site under the heading "GTU". <br />{" "}
          <br />
          ARTICLE 1 : Legal notice The edition and the direction of the
          publication of the weely.ch website is assured by weely.ch, domiciled
          at 4 Chemin des Vignes 1295 Tannay. Telephone number: 0794485377
          E-mail address: contact@weely.ch. The hosts of the weely.ch website
          are: - Wix, host of the weely.ch website (https://www.wix.com/) -
          Netlify, host of the web application app.weely.ch
          (https://www.netlify.com/) - Heroku and Amazon Web Services, host of
          the app.weely.ch application server (https://.heroku.com) - Mlab and
          Amazon Web Services, host of the app.weely.ch application database
          (https://mlab.com/, https://aws.amazon.com/)
          <br /> <br /> ARTICLE 2: Access to the website The weely.ch website
          allows the user free access to the following services: - Provision of
          a customizable digital wheel The website offers the following
          services: - loyalty service - customer acquisition service The website
          is accessible free of charge from any location to any User with
          Internet access. All costs incurred by the User to access the service
          (computer hardware, software, Internet connection, etc.) are at his
          expense. <br /> <br />
          ARTICLE 3 : Data collection The site ensures that the User's personal
          information is collected and processed in compliance with Swiss data
          protection legislation. According to the Swiss law on data collection,
          the user has the right to access, rectify, delete and oppose his
          personal data. This is possible by notifying weely.ch by e-mail.{" "}
          <br /> <br />
          ARTICLE 4: Intellectual property The brands, logos, signs as well as
          all the contents of the site (texts, images, sounds...) are protected
          by the Code of Intellectual Property and more particularly by the
          copyright. The User must request prior authorization from the site for
          any reproduction, publication or copy of the various contents. The
          User undertakes to use the contents of the site in a strictly private
          context; any use for commercial or advertising purposes is strictly
          forbidden. Any total or partial representation of this site by any
          process whatsoever, without the express permission of the operator of
          the website would constitute an infringement punishable by Swiss
          legislation on intellectual property. It is reminded in accordance
          with the Swiss legislation of intellectual property that the User who
          reproduces, copies or publishes the protected content must cite the
          author and its source. <br /> <br />
          ARTICLE 5 : Responsibility The sources of the information published on
          the weely.ch website are deemed reliable but the website does not
          guarantee that it is free of defects, errors or omissions. The
          information provided is presented as an indication and general without
          contractual value. Despite regular updates, the weely.ch website
          cannot be held responsible for changes in administrative and legal
          provisions occurring after publication. Likewise, the website cannot
          be held responsible for the use and interpretation of the information
          contained in this website. The weely.ch site cannot be held
          responsible for any viruses that may infect the computer or any other
          computer equipment of the Internet user, following use, access or
          downloading from this site. The responsibility of the site can not be
          engaged in case of force majeure or unforeseeable and insurmountable
          fact of a third party. or The use of the Site is at your own risk. The
          Site is offered to you 'as is' and 'as available'. Therefore, weely
          does not offer any guarantee. In particular, weely does not guarantee
          that (i) the Site and the products/services offered on it meet your
          requirements; (ii) the Site and its content are complete, accurate,
          reliable, up-to-date and do not infringe the rights of any third
          party; (iii) access to the Site will not be interrupted or error-free,
          or is guaranteed to be free of viruses; (iv) faults will be corrected;
          (v) the Site is secure; or (vi) any advice or opinion provided by
          weely through this Site is accurate and reliable. All information on
          the Site is subject to change or deletion without notice. or TO THE
          FULLEST EXTENT PERMITTED BY LAW, ALL LIABILITY OF weely, ITS
          ORGANIZATIONS, ITS ASSISTANTS AND REPRESENTATIVES, ARISING FROM YOUR
          ACCESS TO OR USE OF THE SITE, IS EXCLUDED. AS A CONDITION OF YOUR USE
          OF THE SITE, YOU AGREE TO INDEMNIFY WEELY, ITS ORGANIZATIONS,
          ASSISTANTS AND REPRESENTATIVES FROM ANY DAMAGES (INCLUDING LEGAL AND
          ATTORNEY FEES) THAT MAY RESULT FROM YOUR ACCESS TO OR USE OF THE SITE.
          <br /> <br />
          ARTICLE 6: Links to other sites Links on the Site may direct you
          outside our networks and systems, in particular to the websites of our
          partners. Weely does not accept any responsibility for the content,
          accuracy or operation of these third party sites. These links are
          provided in good faith and weely cannot be held responsible for the
          content of the third party sites to which a link is provided. The
          inclusion of a link to other websites does not imply endorsement by
          weely. We recommend that you carefully read the legal notice and the
          privacy notice of all other websites you visit. <br /> <br />
          ARTICLE 7: Cookies The user is informed that during his visits to the
          site, a cookie may be automatically installed on his browser. Cookies
          are small files that are temporarily stored on the hard disk of the
          user's computer by your browser and which are necessary for the use of
          the weely.ch website. Cookies do not contain any personal information
          and cannot be used to identify anyone. A cookie contains a unique,
          randomly generated and therefore anonymous identifier. Some cookies
          expire at the end of the user's visit, others remain. The information
          contained in the cookies is used to improve the weely.ch website. By
          browsing the website, the user accepts them. However, the user must
          give his consent to the use of certain cookies. If the user does not
          agree, he/she is informed that certain functions or pages may be
          denied. The User can deactivate these cookies by using the parameters
          of his browser. <br /> <br />
          ARTICLE 9: Processing of communications to weely - Security of e-mails
          Communications that you send to weely via the Internet will not be
          considered confidential. When communicating with us by e-mail, you
          must take into account that the protection of e-mail messages
          transmitted via the Internet is not guaranteed. Therefore, by sending
          important, unencrypted messages by e-mail, you accept the risks of
          this uncertainty. <br /> <br />
          ARTICLE 10: Prohibited activities Weely reserves the right to prohibit
          (e.g. by deleting the content concerned or by limiting/forbidding
          access to all or part of the Site), without prior notice, any act that
          we may deem inappropriate and/or that we may deem to be contrary to
          law or propriety, including (but not limited to) any attempts at
          cheating and abuse that may be detrimental to weely's (or third
          parties') security systems. The user whose access to the website has
          been terminated is no longer entitled to register on the website,
          either under his own name or under another name, unless weely has
          given its prior written consent. <br /> <br />
          ARTICLE 11: Partial nullity Any provision of the Terms of Use which is
          in whole or in part in conflict with mandatory Swiss law shall be
          severable and any nullity, total or partial, of such a clause shall
          not affect the validity of the rest of the clause in question, nor of
          the other clauses of the Terms of Use. If a provision of the Terms of
          Use should be wholly or partially ineffective, it shall be replaced by
          a provision that produces economic and legal effects as close as
          possible to those of the invalidated provision. <br /> <br />
          ARTICLE 12: Miscellaneous The Terms of Use constitute the entire
          agreement between weely and you with regard to access and use of the
          Website and the Content. The fact that weely tolerates breaches by you
          of any of your obligations under the terms of use, or does not demand
          the application of a right which is granted to weely by the terms of
          use or by law, cannot be interpreted as a waiver on its part to avail
          itself of this right. <br /> <br />
          ARTICLE 13 : Applicable law and jurisdiction The Swiss legislation
          applies to the present contract. In the event of failure to resolve a
          dispute between the parties amicably, the Swiss courts will have
          exclusive jurisdiction. For any question relating to the application
          of the present TOS, you can contact the publisher at the coordinates
          listed in ARTICLE 1. Ideas to be possibly integrated: -Participation
          in the Games is governed by the rules of the game, which are an
          integral part of the Terms of Use. All the information and
          documentation contained on the Site (as well as on the pages that we
          animate on social networks) are only presented for information
          purposes and with the aim of promoting our products and services. The
          information and materials presented do not constitute an offer of
          products and/or services from us, our partners or third parties, and
          should not be considered as advice or instructions on the use of our
          products and services. We make every reasonable effort to ensure that
          the information on the Site (as well as on the pages we host on social
          networks) is accurate and complete. However, we do not accept any
          liability if this is not the case. Any reliance you place on such
          information is at your own risk. You agree that it is your
          responsibility to keep yourself informed of any changes to the content
          of the Site. Any liability of weely in this respect is excluded. It is
          the customer's responsibility to inform himself/herself with regard to
          possible restrictions on the use of the products he/she wishes to
          order.
        </p>
      </div>
    </Layout>
  );
};

export default PricePage;
